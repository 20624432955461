/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import * as styles from './CartaCategorySlider.module.scss';
// install Swiper modules
SwiperCore.use([Navigation]);

function CartaCategorySlider(props) {
  const {
    itemCategories,
    location: {
      hash,
    },
  } = props;
  

  return (
    <div className={styles.cartaSliderWrap}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex">
            {/* Enabling free mode gives touch error */}
            {/* freeMode={{ enabled: true }} */}
            {/* Removed space between, added padding on slides */}
            {/* spaceBetween={50} */}
            {/* # is not supported in <Link> */}
            {/* activeClassName won't work with hash */}
            {(itemCategories.length > 0) && 
            <Swiper
              navigation
              className={styles.cartaSlider}
              slidesPerView="auto"
              onInit={(swiper) => {
                // Trigger click on first slide 'a'
                // Added replace on links to replace history state instead of pushing
                // new entry, to go back to restarutant page on browser back button
                // Gatsby Link not keeping class on refresh when hash is in URL
                if (!hash) {
                  swiper.slides[0].children[0].click();
                } else {
                  swiper.slides.forEach((item) => {
                    if (hash === item.children[0].hash) {
                      item.children[0].classList.add(styles.active);
                    }
                  });
                }
              }}
            >
              {itemCategories.map((category) => (
                <SwiperSlide key={category.id} className={styles.swiperSlide}>
                  <Link
                    to={`#${category.slug}`}
                    replace
                    className={hash === `#${category.slug}` ? styles.active : ''}
                  >
                    {category.name}
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

CartaCategorySlider.propTypes = {
  itemCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
export default CartaCategorySlider;
