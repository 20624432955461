import React, {useContext} from "react";
import PropTypes from "prop-types";
import * as styles from "./CartaCategoryContent.module.scss";
import { isBrowser } from "../../utilities/helpers";
import OverlayButton from "../misc/OverlayButton";
import MenuContext from "../../context/menu-context";

function CartaCategoryContent(props) {
  const {
    itemCategories,
    location: { hash },
  } = props;
  const {currentLangCode} = useContext(MenuContext);
  
  const renderAllergyPdfViewerButton = (category) => {
    const pdfURL = category?.itemCategoryDetails?.allergyPdf?.localFile?.publicURL;
    const title = category?.itemCategoryDetails?.allergyPdf?.title;
    if( pdfURL && isBrowser){
      return (
        <OverlayButton
          url={`/${currentLangCode}/${category.slug}/alergenos`}
          text={title}
          side="right"
        />
      );
    }
  }

  const setBackgroundColor = (category) => {
    if (category?.itemCategoryDetails?.backgroundColor) {
      const backgroundColor = category.itemCategoryDetails.backgroundColor;
      if (backgroundColor) {
        const styles = `:root { --carta-section-background-color: ${backgroundColor}; }`;
        return <style type="text/css">{styles}</style>;
      }
    }
    return null;
  };

  const renderContent = () => {
    const category = itemCategories.find((category) => {
      return `#${category.slug}` === hash;
    });
    if (category?.description) {
      return (
        <div className="section-content">
          <div className={[styles.descriptionContainer, "abc"].join(" ")}>
            {setBackgroundColor(category)}
            {renderAllergyPdfViewerButton(category)}
            <div className={styles.descriptionRow}>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: category.description }}
              ></div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  return <>{renderContent()}</>;
}
CartaCategoryContent.propTypes = {
  itemCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      itemCategoryDetails: PropTypes.shape({
        allergyPdf: PropTypes.shape({
          title: PropTypes.string,
          localFile: PropTypes.shape({
            publicURL: PropTypes.string,
          }),
        }),
        backgroundColor: PropTypes.string,
      }),
    }).isRequired
  ).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
export default CartaCategoryContent;
