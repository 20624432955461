import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import MenuBar from "../partials/global/MenuBar";
import Header from "../partials/global/Header";
import CartaCategorySlider from "../partials/restaurantMenu/CartaCategorySlider";
import CartaItemsList from "../partials/item/CartaItemsList";
import OverlayButton from "../partials/misc/OverlayButton";
import { isBrowser } from "../utilities/helpers";
import CartaCategoryContent from "../partials/restaurantMenu/CartaCategoryContent";

function Carta(props) {
  const {
    data: {
      items: { nodes: items },
      itemCategories: { nodes: itemCategories },
      restaurantPdf: {
        slug,
        language: { code },
        restaurantDetails: { allergyPdf },
      },
      restaurantMenu,
    },
    location,
  } = props;
  const [filteredItems, setFilteredItems] = useState([]);
  const { hash } = location;

  const renderAllergyPdfViewerButton = () => {
    // Check if allergy pdf exists and get its URL
    const pdfURL = allergyPdf?.localFile?.publicURL;
    if (pdfURL && isBrowser()) {
      return (
        <OverlayButton
          url={`/${code.toLowerCase()}/${slug}/alergenos`}
          text="Alergenos"
        />
      );
    }
    return null;
  };

  // Filter out not empty category ids
  const notEmptyItemCatIDs = [];
  items.forEach((item) => {
    item.itemCategories.nodes.forEach((itemCat) => {
      if (!notEmptyItemCatIDs.includes(itemCat.id)) {
        notEmptyItemCatIDs.push(itemCat.id);
      }
    });
  });

  // Sort them according to order set from backend
  const sortedNotEmptyItemCategories = itemCategories.filter((itemCat) =>
    notEmptyItemCatIDs.includes(itemCat.id)
  );

  useEffect(() => {
    setFilteredItems(
      hash !== ""
        ? items.filter((item) =>
            item.itemCategories.nodes
              .map((category) => hash === `#${category.slug}`)
              .includes(true)
          )
        : items
    );
  }, [hash, items]);

  return (
    <section className="full-height-section carta-section">
      <Seo post={restaurantMenu} />
      <MenuBar />
      <div className="overflow-hidden flex-grow-1">
        <Header>
          <CartaCategorySlider
            itemCategories={sortedNotEmptyItemCategories}
            location={location}
          />
        </Header>
          <CartaCategoryContent itemCategories={sortedNotEmptyItemCategories} location={location}/>
        <div className="section-content">
          <CartaItemsList items={filteredItems} />
        </div>
      </div>
      {renderAllergyPdfViewerButton()}
    </section>
  );
}

export const query = graphql`
  query (
    $languageCode: WpLanguageCodeEnum
    $restaurantId: String
    $menuId: String
  ) {
    items: allWpItem(
      filter: {
        restaurantMenus: { nodes: { elemMatch: { id: { eq: $menuId } } } }
        restaurants: { nodes: { elemMatch: { id: { eq: $restaurantId } } } }
      }
      sort: { fields: menuOrder, order: ASC }
    ) {
      nodes {
        id
        title
        itemDetails {
          shortDescription
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
              }
            }
          }
        }
        itemCategories {
          nodes {
            id
            slug
            name
            description
          }
        }
      }
    }
    itemCategories: allWpItemCategory(
      filter: { language: { code: { eq: $languageCode } } }
      sort: { order: ASC, fields: termOrder }
    ) {
      nodes {
        id
        slug
        name
        description
        itemCategoryDetails {
          allergyPdf {
            title
            localFile {
              publicURL
            }
          }
          backgroundColor
        }
      }
    }
    restaurantPdf: wpRestaurant(id: { eq: $restaurantId }) {
      slug
      language {
        code
      }
      restaurantDetails {
        allergyPdf {
          localFile {
            publicURL
          }
        }
      }
    }
    restaurantMenu: wpRestaurantMenu(id: { eq: $menuId }) {
      id
      slug
      name
      seo {
        ...YoastTaxonomySEOContent
      }
    }
  }
`;

Carta.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          itemCategories: PropTypes.shape({
            nodes: PropTypes.arrayOf(
              PropTypes.shape({
                slug: PropTypes.string.isRequired,
              }).isRequired
            ).isRequired,
          }).isRequired,
        })
      ).isRequired,
    }).isRequired,
    itemCategories: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          itemCategoryDetails: PropTypes.shape({
            allergyPdf: PropTypes.shape({
              title: PropTypes.string,
              localFile: PropTypes.shape({
                publicURL: PropTypes.string
              })
            }),
            backgroundColor: PropTypes.string
          })
        }).isRequired
      ).isRequired,
    }).isRequired,
    restaurantPdf: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      language: PropTypes.shape({
        code: PropTypes.string.isRequired,
      }).isRequired,
      restaurantDetails: PropTypes.shape({
        allergyPdf: PropTypes.shape({
          localFile: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
          }),
        }),
      }).isRequired,
    }).isRequired,
    restaurantMenu: PropTypes.shape({}).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
export default Carta;
